<template>
  <div
    id="NftPool"
    class="nft_pool_detail"
    :class="{'pb-0': artpoolSimpleInfo.roundNo === 4}"
    :style="{backgroundImage: `url(${artpoolSimpleInfo.backgroundImageUrl})`}"
  >

    <div class="top">
      <div class="left">
        <div class="title">{{$t("nftpool.introduction")}}</div>
        <div class="description">
          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
            {{ artpoolSimpleInfo.description || artpoolSimpleInfo.descriptionEn }}
          </template>
          <template v-else>
            {{ artpoolSimpleInfo.descriptionEn || artpoolSimpleInfo.description }}
          </template>
        </div>
      </div>
      <div class="right">
        <img
          v-if="artpoolSimpleInfo.bannerImagePcEnUrl && activeLanguage === 'en'"
          :src="artpoolSimpleInfo.bannerImagePcEnUrl"
        />
        <img
          v-else-if="artpoolSimpleInfo.bannerImagePcUrl && activeLanguage === 'ko'"
          :src="artpoolSimpleInfo.bannerImagePcUrl"
        />
        <img
          v-else
          :src="`/img/threespace/artpool/artpool_round${artpoolSimpleInfo.roundNo}_banner.png`"
        />
      </div>
    </div>

    <div class="middle">
      <div class="item-wrap">
        <div class="item">
          <div class="text-wrap">
            <div class="title">{{ $t("nftpool.artists") }}</div>
            <div class="description">
              <template v-if="artpoolSimpleInfo.artists != undefined && artpoolSimpleInfo.artists.length > 0">
                <div
                  class="artist_item"
                  v-for="(item, i) in artpoolSimpleInfo.artists"
                  :key="'artist' + i"
                >
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.artistName || item.artistNameEn }}
                  </template>
                  <template v-else>
                    {{ item.artistNameEn || item.artistName }}
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="artist_item">
                  참가 중인 작가가 없습니다.
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="text-wrap">
            <div class="title">
              {{ $t("nftpool.stakeNft") }}
            </div>
            <div class="description">
              {{ $t("nftpool.stakeNftContent") }}
            </div>
          </div>
          <b-button
            @click="onClickGoStakePage"
            :style="{backgroundColor: artpoolSimpleInfo.primaryColor}"
            :class="{round5: artpoolSimpleInfo.roundNo === 5}"
          >
            {{ $t("nftpool.stakeNft") }}
          </b-button>
        </div>
        <div class="item">
          <div class="text-wrap">
            <div class="title">
              {{ $t("nftpool.vote") }}
            </div>
            <div class="description">
              {{ $t("nftpool.voteContent") }}
            </div>
          </div>
          <b-button
            @click="onClickGoVotePage"
            :style="{backgroundColor: artpoolSimpleInfo.secondaryColor}"
            :class="{round5: artpoolSimpleInfo.roundNo === 5}"
          >
            {{ $t("nftpool.vote") }}
          </b-button>
        </div>
      </div>
    </div>

    <div class="vote-result">
      <div class="title-wrap">
        <div class="title">
          {{ $t("nftpool.finalVote") }}
        </div>
        <div class="subtitle">
          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
            <template v-if="$moment().isAfter(artpoolSimpleInfo.voteEndDate)">
              {{ artpoolSimpleInfo.voteEndDate | moment("MM.DD") }} {{ $t("nftpool.finalVoteContentComplete") }}
            </template>
            <template v-else>
              {{ artpoolSimpleInfo.voteEndDate | moment("MM.DD") }} {{ $t("nftpool.finalVoteContent") }}
            </template>
          </template>
          <template v-else>
            {{ artpoolSimpleInfo.voteEndDate | moment("Do, MMM") }}
          </template>
        </div>
      </div>
      <div class="winner">
        <div class="result_item kip_item" v-if="topKip !== undefined && $moment().isAfter(artpoolSimpleInfo.voteEndDate)">
          <div
            class="img_box"
            :style="{color: artpoolSimpleInfo.primaryColor}"
            :class="{round5: artpoolSimpleInfo.roundNo === 5}"
          >
            <template v-if="topKip.winnerCollection.imageUrl !== undefined && topKip.winnerCollection.imageUrl !== null && topKip.winnerCollection.imageUrl !== ''">
              <img :src="topKip.winnerCollection.imageUrl" />
            </template>
          </div>
          <div class="text_box">
            <div class="main_text">
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ topKip.winnerCollection.title || topKip.winnerCollection.titleEn }}
                </template>
                <template v-else>
                  {{ topKip.winnerCollection.titleEn || topKip.winnerCollection.title }}
                </template>
              <div class="vote_text">
                <p class="pace">
                  <img src="/img/threespace/company/3space-art.png" />
                  {{ Number(topKip.winningVote) | currency }}
                </p>
                <p class="kpace">
                  <img src="/img/threespace/icon/vote_icon.png" />
                  {{ Number(topKip.userVoteAmount) | currency }}
                </p>
              </div>
              <p>
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  Artist @{{ topKip.winnerCollection.artist || topKip.winnerCollection.artistEn }}
                </template>
                <template v-else>
                  Artist @{{ topKip.winnerCollection.artistEn || topKip.winnerCollection.artist }}
                </template>
                <br />
                #{{ topKip.winnerCollection.tokenId }} ({{ topKip.winnerCollection.nftType }})
              </p>
            </div>
          </div>
        </div>
        <div class="result_item kip_item" v-else>
          <img
            src="/img/threespace/artpool/no-artwork.png"
          />
          <div class="text_box" v-if="$moment().isAfter(artpoolSimpleInfo.voteEndDate)">
            <div class="main_text">
              {{ $t("nftpool.no1stPlace") }}
            </div>
          </div>
        </div>
        <div class="result_item erc_item" v-if="topErc !== undefined && $moment().isAfter(artpoolSimpleInfo.voteEndDate)">
          <div
            class="img_box"
            :style="{color: artpoolSimpleInfo.secondaryColor}"
            :class="{round5: artpoolSimpleInfo.roundNo === 5}"
          >
            <template v-if="topErc.winnerCollection.imageUrl !== undefined && topErc.winnerCollection.imageUrl !== null && topErc.winnerCollection.imageUrl !== ''">
              <img :src="topErc.winnerCollection.imageUrl" />
            </template>
          </div>
          <div class="text_box">
            <div class="main_text">
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ topErc.winnerCollection.title || topErc.winnerCollection.titleEn }}
                </template>
                <template v-else>
                  {{ topErc.winnerCollection.titleEn || topErc.winnerCollection.title }}
                </template>
              <div class="vote_text">
                <p class="pace">
                  <img src="/img/threespace/company/3space-art.png" />
                  {{ Number(topErc.winningVote) | currency }}
                </p>
                <p class="kpace">
                  <img src="/img/threespace/icon/vote_icon.png" />
                  {{ Number(topErc.userVoteAmount) | currency }}
                </p>
              </div>
              <p>
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  Artist @{{ topErc.winnerCollection.artist || topErc.winnerCollection.artistEn }}
                </template>
                <template v-else>
                  Artist @{{ topErc.winnerCollection.artistEn || topErc.winnerCollection.artist }}
                </template>
                <br />
                #{{ topErc.winnerCollection.tokenId }} ({{ topErc.winnerCollection.nftType }})
              </p>
            </div>
          </div>
        </div>
        <div class="result_item erc_item" v-else>
          <img
            src="/img/threespace/artpool/no-artwork.png"
          />
          <div class="text_box" v-if="$moment().isAfter(artpoolSimpleInfo.voteEndDate)">
            <div class="main_text">
              {{ $t("nftpool.no1stPlace") }}
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="bottom">
      <div class="nft-pool-history">
        <div class="title">PREVIOUS EXHIBITIONS</div>
        <div class="nft-pool-list">
          <template v-for="(item,i ) in artpoolItems.content">
            <div
              :key="'artpool-list' + i"
              @click="onClickArtPoolListItem(item)"
              class="item"
              :class="{active: artpoolSimpleInfo.idx === item.idx}"
            >
              <div class="img_box">
                <img :src="item.thumbnailImageUrl" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Artpool Round4 -->
    <div class="nft_pool_bg_round4" v-if="artpoolSimpleInfo.roundNo === 4">
    </div>

    <!-- pool aside banner ============================= -->
    <aside
      class="pool_aside_banner detail_banner"
      :class="{ show: scrollPosition > 400 && scrollPosition < 2500 }"
    >
      <div class="top_img_box">
        <img :src="`/img/threespace/artpool/art_pool`+ artpoolSimpleInfo.roundNo +`_logo.png`" />
      </div>
      <div class="bottom_img_box">
        <div class="vertical_border" :style="{ backgroundColor: getAsideBorderColor(artpoolSimpleInfo.roundNo) }"></div>
        <div class="date_box">
          {{ artpoolSimpleInfo.poolStartDate | moment("MM.DD") }}
          &nbsp;-&nbsp;
          {{ artpoolSimpleInfo.poolEndDate | moment("MM.DD") }}
        </div>
        <img src="/img/threespace/artpool/art_pool3_object.png" v-if="artpoolSimpleInfo.roundNo === 3" />
      </div>
    </aside>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "NftPoolDetail",
  props: {
    idxArtpool: {
      type: Number,
      default: 0
    }
  },
  components: {},
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.artPool.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.artPool.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ],
    }
  },
  data: function () {
    return {
      isBusy: false,
      perPage: 6,
      currentPage: 1,
      sort: [ { id: "roundNo", desc: "desc" } ],
      externalParams: {
        keywordPoolType: "NFT",
      },
      scrollPosition: 0,
      asideBorderColor: {
        4: "#04b69d" // key=roundNo
      }
    }
  },
  computed: {
    ...mapState("artpool", [
      "artpoolItems",
      "artpoolVersionOptions",
      "artpoolSimpleInfo",
      "topRankCollections"
    ]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    topErc() {
      if (this.topRankCollections.topErc !== undefined) {
        return this.topRankCollections.topErc;
      } else {
        return undefined;
      }
    },
    topKip() {
      if (this.topRankCollections.topKip !== undefined) {
        return this.topRankCollections.topKip;
      } else {
        return undefined;
      }
    }
  },
  mounted() {
    this.requestArtpools()
    this.requestArtpool()
    this.requestArtpoolTopRank()
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions("artpool",[
      "getArtpoolSimple",
      "getArtpoolCollectionsTopRank"
    ]),
    handleScroll (e) {
      this.scrollPosition = window.scrollY;
    },
    requestArtpool() {
      this.isBusy = true;
      this.getArtpoolSimple(this.idxArtpool)
        .then(() => {
          this.isBusy = false
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    requestArtpoolTopRank() {
      this.isBusy = true;
      this.getArtpoolCollectionsTopRank(this.idxArtpool)
        .then(() => {
          this.isBusy = false
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickArtPoolListItem(artpool) {
      if(artpool.roundNo < 3 ) {
        this.$router.push({ name: "NftPoolStake", params: { idxArtpool: artpool.idx }})
      } else if(artpool.roundNo >=3 ) {
        this.$router.push({ name: "NftPoolDetail", params: { idxArtpool: artpool.idx }})
      }
    },
    onClickBackBtn() {
      this.$router.push({ name: "NftPool"})
    },
    ...mapActions("artpool", ["getArtpools"]),
    requestArtpools() {
      this.$store.commit("setShowLoading", {show: true, msg: ""});
      this.isBusy = true;
      this.getArtpools(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
      .then(() => {
        this.isBusy = false;
        this.$store.commit("setShowLoading", { show: false, msg: "" });
      })
      .catch(error => {
        this.$log.error(error);
        this.isBusy = false;
        this.$store.commit("setShowLoading", { show: false, msg: "" });
      });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }
      return params;
    },
    onClickGoStakePage() {
      if (this.$moment().isBefore(this.artpoolSimpleInfo.poolStartDate)) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.notInProgressPool"),
          show: true,
          fontSize: "sm"
        })
      } else {
        this.$router.push({ name: "NftPoolStake", params: { idxArtpool: this.idxArtpool }})
      }
    },
    onClickGoVotePage() {
      if (this.$moment().isBefore(this.artpoolSimpleInfo.voteStartDate)) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.vote.notInProgress"),
          show: true,
          fontSize: "sm"
        })
      } else {
        this.$router.push({ name: "NftPoolVote", params: { idxArtpool: this.idxArtpool }})
      }
    },
    getAsideBorderColor(roundNo) {
      if (roundNo !== undefined && roundNo !== null && roundNo > 0) {
        const colorCode = this.asideBorderColor[roundNo];
        if (colorCode !== undefined && colorCode !== null && colorCode !== "") {
          return colorCode;
        }
        return "#000";
      }
      return "#000";
    }
  },
  filters: {
    decimal: value => {
      if (!value) return 0;
      return (Math.floor((value * 100) / 10 ** 18) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currency: value => {
      if (!value) return 0;
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  },
}
</script>
